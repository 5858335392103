import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap'
import { Rating } from '../components/Rating'
import { 
  listProductDetails,
  createProductReview, 
} from '../actions/productActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Meta from '../components/Meta'
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'

export const ProductPages = ({ match, history }) => {
  const [qty, setQty] = useState(1)
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')

  const dispatch = useDispatch()

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const productDetails = useSelector(state => state.productDetails)
  const { loading, error, product } = productDetails

  const productCreateReview = useSelector(state => state.productCreateReview)
  const { 
    error: errorProductReview, 
    success: successProductReview 
  } = productCreateReview

  useEffect(() => {
    if(successProductReview){
      alert('review added!')
      setRating(0)
      setComment('')
      dispatch({type: PRODUCT_CREATE_REVIEW_RESET})
    }

    dispatch(listProductDetails(match.params.id))

  }, [dispatch, match, successProductReview,])

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`)
  }
  
  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(createProductReview(match.params.id, {
      rating,
      comment,
    }))
  }

  return (
    <>
      <Link to='/' className='btn btn-primary btn-sm my-3' >Back</Link>

      {loading
        ? ( <Loader /> )
        : error
        ? ( <Message variant='danger'>{error}</Message> )
        : (
          <>
          <Meta title={product.name} />
          <Row>
            <Col md={5}>
              <Image src={product.image} alt={product.name} fluid />
            </Col>

            <Col md={4}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <h3>{product.name}</h3>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Rating 
                    value={product.rating}
                    text={`${product.numReviews} reviews`}
                  />
                </ListGroup.Item>

                <ListGroup.Item>Price: IDR {product.price}</ListGroup.Item>
                <ListGroup.Item>Description: {product.description}</ListGroup.Item>
              </ListGroup>
            </Col>

            <Col md={3}>
              <Card>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <Row>
                      <Col>Price:</Col>
                      <Col><strong>IDR {product.price}</strong></Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Row>
                      <Col>Stock:</Col>
                      <Col>{product.countInStock > 0 ? 'In Stock' : 'Out Of Stock'}</Col>
                    </Row>
                  </ListGroup.Item>

                  {product.countInStock > 0 && (
                    <ListGroup.Item>
                      <Row>
                        <Col>Qty:</Col>
                        <Col>
                          <Form.Control as='select' value={qty} onChange={e => setQty(e.target.value)}>
                          {[...Array(product.countInStock).keys()].map(x => (
                            <option key={x + 1} value={x + 1}>
                              {x + 1}
                            </option>
                          ))}
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}

                  <ListGroup.Item>
                    <Button
                      onClick={addToCartHandler} 
                      className='btn-block' 
                      type='button'
                      disabled={product.countInStock === 0}
                    >Add to Cart
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>

          <Row className='my-4'>
            <Col md={6}>
              <h3>Reviews</h3>
              {product.reviews.length === 0 && <Message>No reviews</Message>}
              <ListGroup variant='flush'>
                {product.reviews.map((review) => (
                  <ListGroup.Item key={review._id}>
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <p>{review.createdAt.substring(0, 10)}</p>
                    <p>{review.comment}</p>
                  </ListGroup.Item>
                ))}

                <ListGroup.Item>
                  <h4>Write a Review</h4>
                  {errorProductReview && (<Message variant='danger'>{errorProductReview}</Message>)}
                  {userInfo 
                    ? (
                      <Form onSubmit={submitHandler}>
                        <Form.Group controlId='rating'>
                          <Form.Label>Rating</Form.Label>
                          <Form.Control as='select' value={rating} onChange={e => setRating(e.target.value)}>
                            <option value='' disabled>Select Rating</option>
                            <option value='1'>1 - Bad</option>
                            <option value='2'>2 - Poor/Fair</option>
                            <option value='3'>3 - Good</option>
                            <option value='4'>4 - Very Good</option>
                            <option value='5'>5 - Excelent</option>
                          </Form.Control> 
                        </Form.Group>

                        <Form.Group controlId='comment'>
                          <Form.Label>Comment</Form.Label>
                          <Form.Control
                            as='textarea'
                            row='3'
                            value={comment}
                            onChange={e => setComment(e.target.value)}
                          ></Form.Control>
                        </Form.Group>

                        <Button type='submit' variant='primary'>
                          Submit
                        </Button>
                      </Form>
                    )
                    : ( <Message>Please <Link to='/login'>sign in</Link> to write a review</Message> )
                  }
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          </>
        )  
      }
    </>
  )
}
