import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Button, Table} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listOrders } from '../actions/orderActions'

export const OrderListPages = ({ history }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const orderList = useSelector(state => state.orderList)
  const { loading, error, orders } = orderList

  useEffect(() => {
    if(!userInfo) {
      history.push('/login')
    } else if (!userInfo.isAdmin) {
      history.push('/')
    } else {
    dispatch(listOrders())
    }
    
  }, [dispatch, history, userInfo])

  return (
    <>
      <h2>Orders</h2>
      {loading 
        ? (<Loader />)
        : error
        ? (<Message variant='danger'>{error}</Message>)
        : (
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>ID</th>
                <th>USER</th>
                <th>DATE</th>
                <th>TOTAL</th>
                <th>PAID</th>
                <th>DELIVERED</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {orders.map((order) => (
                <tr key={order._id}>
                  <td>{order._id}</td>
                  <td>{order.user && order.user.name}</td>
                  <td>{order.createdAt.substring(0, 10)}</td>
                  <td>IDR {order.totalPrice}</td>
                  <td>{order.isPaid
                    ? (order.paidAt.substring(0, 10))
                    : (<i className='fas fa-times' style={{color: 'red'}}></i>)}
                  </td>
                  <td>{order.isDelivered
                    ? (order.deliveredAt.substring(0, 10))
                    : (<i className='fas fa-times' style={{color: 'red'}}></i>)}
                  </td>
                  <td>
                    <LinkContainer to={`/order/${order._id}`}>
                      <Button variant='dark' className='btn-sm'>
                        Details
                      </Button>
                    </LinkContainer>

                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )
      }
    </>
  )
}
