import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Button, Table} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listUsers, deleteUser } from '../actions/userActions'

export const UserListPages = ({ history }) => {
  const dispatch = useDispatch()

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const userList = useSelector(state => state.userList)
  const { loading, error, users } = userList

  const userDelete = useSelector(state => state.userDelete)
  const { success:successDelete } = userDelete

  useEffect(() => {
    if(!userInfo) {
      history.push('/login')
    } else if (!userInfo.isAdmin) {
      history.push('/')
    } else {
    dispatch(listUsers())
    }
    
  }, [dispatch, history, userInfo, successDelete])

  const deleteHandler = (id) => {
    if(window.confirm('Are you sure want to delete this?')) {
      dispatch(deleteUser(id))
    }
  }

  return (
    <>
      <h2>Users</h2>
      {loading 
        ? (<Loader />)
        : error
        ? (<Message variant='danger'>{error}</Message>)
        : (
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>ADMIN</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user._id}</td>
                  <td>{user.name}</td>
                  <td><a href={`mailto:${user.email}`}>{user.email}</a></td>
                  <td>{user.isAdmin
                    ? (<i className='fas fa-check' style={{color: 'green'}}></i>)
                    : (<i className='fas fa-times' style={{color: 'red'}}></i>)}
                  </td>
                  <td>
                    <LinkContainer to={`/admin/user/${user._id}/edit`}>
                      <Button variant='dark' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>

                    <Button 
                      variant='danger' 
                      className='btn-sm'
                      onClick={() => deleteHandler(user._id)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )
      }
    </>
  )
}
